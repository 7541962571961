<template>
  <div class="jobs p-8 mx-auto max-w-5xl">
    <div class="space-y-6">
      <div class="flex justify-between pb-2 items-center">
        <h3 class="text-3xl font-semibold">Job {{ job.sequence_number }}</h3>

        <router-link
          v-if="!error"
          :to="`/jobs/${job.id}/edit`"
          class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700"
        >
          <span class="hover-target pt-1 font-medium">Edit</span>
          <svg
            class="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        </router-link>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <div v-if="!error"></div>

      <div
        v-if="!error && job.job_completed"
        class="shadow overflow-hidden border border-gray-200"
      >
        <table
          class="w-full border-8 border-gray-600 divide-y-8 divide-gray-600"
        >
          <tr class="bg-gray-600">
            <td class="p-2 text-white font-semibold uppercase bg-gray-600">
              Internal Use
            </td>
          </tr>
          <tr>
            <td>
              <table class="w-full divide-y divide-gray-200">
                <thead>
                  <tr
                    class="bg-gray-100 text-sm font-medium text-gray-900 uppercase text-left"
                  >
                    <th class="px-6 py-4 text-center w-sm">Options</th>
                    <th class="px-6 py-4 w-sm text-center">Completed</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="hover:bg-gray-50 border-b text-left">
                    <td
                      class="px-6 py-3 flex justify-center gap-4"
                      v-if="job.job_completed"
                    >
                      <router-link
                        v-if="!error"
                        :to="`/jobs/${job_id}/email`"
                        title="Send Completion Email"
                        class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                          />
                        </svg>
                      </router-link>
                    </td>
                    <td class="px-6 py-3 flex justify-center gap-4" v-else>
                      <span title="No serives to print"> N/A </span>
                    </td>
                    <!-- <td class="px-6 py-4 text-sm text-gray-500 capitalize">
                      {{ quote.quote_status }}
                    </td> -->
                    <td class="px-6 py-4 text-sm text-gray-500 text-center">
                      {{ job.job_completed ? "&check;" : "" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr
            v-if="
              job.job_notes?.length > 0 ||
                job.customer?.customer_notes?.length > 0 ||
                job.location?.location_notes?.length > 0
            "
          >
            <td>
              <table class="w-full">
                <tr
                  v-if="job.job_notes?.length > 0"
                  class="hover:bg-gray-50 border-b"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
                  >
                    Job Notes
                  </td>
                  <td
                    class="px-6 py-4 text-sm text-gray-500 whitespace-pre-wrap w-full"
                  >
                    {{ job.job_notes }}
                  </td>
                </tr>

                <tr
                  v-if="job.customer?.customer_notes?.length > 0"
                  class="hover:bg-gray-50 border-b"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
                  >
                    Customer Notes
                  </td>
                  <td
                    class="px-6 py-4 text-sm text-gray-500 whitespace-pre-wrap w-full"
                  >
                    {{ job.customer.customer_notes }}
                  </td>
                </tr>

                <tr
                  v-if="job.location?.location_notes?.length > 0"
                  class="hover:bg-gray-50 border-b"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
                  >
                    Location Notes
                  </td>
                  <td
                    class="px-6 py-4 text-sm text-gray-500 whitespace-pre-wrap w-full"
                  >
                    {{ job.location.location_notes }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>

      <div v-if="!error" class="shadow overflow-hidden border border-gray-200">
        <table class="w-full divide-y divide-gray-200">
          <thead>
            <tr
              class="bg-gray-100 text-sm font-medium text-gray-900 uppercase text-left"
            >
              <th colspan="2" class="px-6 py-4">
                Job Details
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Date Scheduled
              </td>
              <td class="px-6 py-4 text-sm text-gray-500 w-full">
                {{ dateFromISO(job.job_scheduled) }}
              </td>
            </tr>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Crew
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ job?.crew?.crew_name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="!error && job.customer"
        class="shadow overflow-hidden border border-gray-200"
      >
        <table class="w-full divide-y divide-gray-200">
          <thead>
            <tr
              class="bg-gray-100 text-sm font-medium text-gray-900 uppercase text-left"
            >
              <th colspan="2" class="px-6 py-4">
                Customer
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Business Name
              </td>
              <td class="px-6 py-4 text-sm text-gray-500 w-full">
                {{ job.customer.billing_business }}
              </td>
            </tr>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Name
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ job.customer.customer_name }}
              </td>
            </tr>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Phone
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ job.customer.customer_phone }}
              </td>
            </tr>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Email
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ job.customer.customer_email }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="!error && job.location"
        class="shadow overflow-hidden border border-gray-200"
      >
        <table class="w-full divide-y divide-gray-200">
          <thead>
            <tr
              class="bg-gray-100 text-sm font-medium text-gray-900 uppercase text-left"
            >
              <th colspan="2" class="px-6 py-4">
                Location
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Address
              </td>
              <td class="px-6 py-4 text-sm text-gray-500 w-full">
                <div>{{ job.location.location_address1 }}</div>
                <div v-if="job.location.location_address2">
                  {{ job.location.location_address2 }}
                </div>
                <div>
                  {{ job.location.location_city }}
                  {{ job.location.location_state }}
                  {{ job.location.location_zip }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="!error && job.attachments?.length > 0"
        class="shadow overflow-hidden border border-gray-200"
      >
        <table class="table-auto w-full divide-y divide-gray-200">
          <thead>
            <tr
              class="bg-gray-100 text-sm font-medium text-gray-900 uppercase text-left"
            >
              <th class="px-6 py-4">Attachments</th>
            </tr>
          </thead>
          <tbody>
            <tr class="hover:bg-gray-50 border-b">
              <td class="p-4">
                <div class="flex flex-wrap gap-1">
                  <div
                    v-for="image in job.attachments"
                    :key="image"
                    @click="modalImage(`${image.url}`)"
                  >
                    <img :src="image.url" class="w-28 h-28 object-cover" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="!error" class="shadow overflow-hidden border border-gray-200">
        <table class="table-auto w-full divide-y divide-gray-200">
          <thead>
            <tr
              class="bg-gray-100 text-sm font-medium text-gray-900 uppercase text-left"
            >
              <th class="px-6 py-4">Service</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="service_line of job.service_lines"
              :key="service_line"
            >
              <tr class="hover:bg-gray-50 border-b">
                <td
                  class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap align-top"
                >
                  {{ service_line.service_name }}
                </td>
              </tr>
              <tr
                v-if="service_line.images?.length > 0"
                class="hover:bg-gray-50 border-b"
              >
                <td colspan="5" class="p-4">
                  <div class="flex flex-wrap gap-1">
                    <div
                      v-for="image in service_line.images"
                      :key="image"
                      @click="modalImage(`${image.url}`)"
                    >
                      <img :src="image.url" class="w-28 h-28 object-cover" />
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div class="text-center text-red-600 font-semibold">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import getCollection from "@/composables/getCollection";
import getDocument from "@/composables/getDocument";
import useLuxon from "@/composables/useLuxon";

export default {
  props: ["job_id", "user"],
  emits: ["loadModalImage"],
  setup(props, context) {
    const isLoading = ref(true);
    const route = useRoute();

    const business_id = props.user.claims.business_id ?? "";

    const { error, document: job } = getDocument(
      `businesses/${business_id}/jobs`,
      props.job_id
    );

    const {
      error: service_lines_err,
      documents: service_lines,
    } = getCollection(
      `businesses/${business_id}/jobs/${props.job_id}/service_lines`
    );

    const modalImage = (data) => {
      context.emit("loadModalImage", data);
    };

    const { dateFromISO, dateFromSeconds } = useLuxon();

    watchEffect(() => {
      if (job.value) {
        isLoading.value = false;
      }
    });

    return {
      isLoading,
      route,
      error,
      job,
      service_lines_err,
      dateFromISO,
      dateFromSeconds,
      service_lines,
      modalImage,
    };
  },
};
</script>
<style scoped>
.spinner.filled {
  border-top: 5px solid #3b82f6;
  border-right: 5px solid #3b82f6;
}
</style>
